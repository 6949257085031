import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';
import shadow from '../../styles/atoms/shadows';

const colorHierarchy = {
  light: {
    kicker: colors.primary[500],
    heading: colors.gray[900],
    subhead: colors.gray[600],
  },
  charcoal: {
    kicker: colors.primary[300],
    heading: colors.base.white,
    subhead: colors.base.white,
  },
  blue: {
    kicker: colors.base.white,
    heading: colors.base.white,
    subhead: colors.base.white,
  },
};

export const VideoWrapper = styled.div`
  width: 100%;
  max-width: 970px;
  margin: auto;
  border-radius: 12px;
  overflow: hidden;
  ${shadow('xxl')}
`;

export const Kicker = styled.p`
  ${font('overline', 'sm', '700')}
  color: ${props => colorHierarchy[props.variant].kicker};
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${props => colorHierarchy[props.variant].heading};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}
`;

export const Subhead = styled.div`
  ${font('text', 'md', '400')}
  color: ${props => colorHierarchy[props.variant].subhead};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  max-width: 970px;
  margin: 0 auto;
  text-align: center;

  ${atMinWidth.sm`
    gap: 48px;
  `}

  ${atMinWidth.md`
    gap: 56px
  `}

  ${atMinWidth.lg`
    gap: 74px
  `}
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
`;
